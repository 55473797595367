import React from "react";
import { BenefitCard, Image, TextButton, Typography } from "zera";
import { ITypeSvgComponent } from "zera/lib/typescript/assets/components-aws/types";

import { PaVirtualHeroIllustration } from "../../assets/image/illustration";
import { showTexts } from "../../helpers";
import {
  HeroCards,
  HeroCardsContainer,
  HeroContainer,
  HeroContent,
  HeroContentContainer,
  HeroImageContainer,
  HighlightTextContainer,
  TextButtonContainer
} from "./style";

interface IHeroProps {
  internationalization: "enUS" | "es" | "ptBR";
}

export const Hero: React.FunctionComponent<IHeroProps> = (props) => {
  const showTextsi18n = showTexts(props.internationalization);
  const [width, setWindowWidth] = React.useState(0);

  const showDesktopComponent: boolean = width > 660;

  const updateDimensions = () => {
    const width = window.outerWidth;
    setWindowWidth(width);
  };

  React.useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const heroData = {
    heroContent: {
      highlightText: showTextsi18n.heroContent.highlightText,
      secondaryText: showTextsi18n.heroContent.secondaryText,
      buttonText: showTextsi18n.heroContent.buttonText,
      buttonOnPress: () => {
        const plansSection = document.getElementById("plans");

        if (plansSection) {
          const headerHeight = 130;
          const plansTop =
            plansSection.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: plansTop - headerHeight,
            behavior: "smooth"
          });
        }
      }
    }
  };

  return (
    <HeroContainer id="home">
      <HeroContentContainer>
        <HeroContent>
          <HighlightTextContainer>
            <Typography
              fontFamily="Work Sans"
              fontSize={showDesktopComponent ? "10" : "9"}
              fontWeight={400}
              lineHeight="1.1"
              text={heroData.heroContent.highlightText}
            />
          </HighlightTextContainer>
          <Typography
            color="#7F99A6"
            fontFamily="Inter"
            fontSize={showDesktopComponent ? "5" : "3"}
            fontWeight={400}
            lineHeight={showDesktopComponent ? "1.2" : "1.3"}
            text={heroData.heroContent.secondaryText}
          />
          <TextButtonContainer>
            <TextButton
              label={heroData.heroContent.buttonText}
              onPress={heroData.heroContent.buttonOnPress}
              type="primary"
              width="20rem"
            />
          </TextButtonContainer>
        </HeroContent>

        <HeroImageContainer>
          <Image
            source={PaVirtualHeroIllustration}
            proportion="4-3"
            width="100%"
          />
        </HeroImageContainer>
      </HeroContentContainer>

      <HeroCards>
        {showTextsi18n.heroCards.map((heroCard, index: number) => {
          return (
            <HeroCardsContainer
              key={index}
              width={showDesktopComponent ? "33.3333333333%" : "14.375rem"}
              height={showDesktopComponent ? "auto" : "15.25rem"}
            >
              <BenefitCard
                listSvg={heroCard.icon as ITypeSvgComponent}
                title={heroCard.title}
                description={heroCard.description}
                colorSvgPrimary="#0076DA"
                colorSvgSecondary="#F8FAFA"
                colorTitle="#161E23"
                colorDescription="#415B68"
                type="lib-icon"
              />
            </HeroCardsContainer>
          );
        })}
      </HeroCards>
    </HeroContainer>
  );
};
