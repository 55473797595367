import styled from "styled-components";

export const HeroContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;

  margin: 2.5rem 0;

  @media (max-width: 990px) {
    margin: 1.5rem 0;
  }

  @media (min-width: 1440px) {
    max-width: 1180px;
    margin: 2.5rem auto;
    padding-left: 4rem;
  }
`;

export const HeroContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 990px) {
    flex-direction: column-reverse;
  }
`;

export const HeroContent = styled.div`
  margin-top: 5.438rem;
  margin-left: 8.89vw;
  /* margin-left: 13.89vw; */
  margin-bottom: 5.438rem;

  > div:nth-child(1),
  > div:nth-child(2) {
    width: 520px;
  }

  @media (min-width: 1440px) {
    margin-left: 0;
  }

  @media (max-width: 1260px) {
    margin-top: 0;
  }

  @media (min-width: 768px) and (max-width: 1259px) {
    margin: 0 2.5rem;
  }

  @media (max-width: 767px) {
    > div:nth-child(1),
    > div:nth-child(2) {
      width: unset;
    }

    margin: 0 1.5rem;
  }

  @media (max-width: 359px) {
    width: 80%;
  }
`;

export const HighlightTextContainer = styled.div`
  margin-bottom: 1rem;
`;

export const TextButtonContainer = styled.div`
  margin-top: 2.5rem;
`;

export const HeroImageContainer = styled.div`
  width: 50vw;
  margin-right: 4vw;
  margin-bottom: 2.5rem;

  position: relative;
  left: 3.5rem;

  div {
    div {
      background-size: cover;
    }
  }

  @media (min-width: 1440px) {
    margin-right: 0;
    left: 2.5rem;
  }

  @media (max-width: 1240px) {
    margin-right: 0;
    position: inherit;
    left: 0;
  }

  @media (max-width: 990px) {
    width: auto;

    margin-top: 0;
    margin-bottom: 2.5rem;
  }
`;

interface IProgressCircleProps {
  height: string;
  width: string;
}

export const HeroCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  /* width: 72.22vw;
  margin: 0 13.89vw; */

  margin: 0 8.89vw;

  @media (min-width: 1440px) {
    max-width: 1040px;
    margin: 0;
  }

  @media (min-width: 768px) and (max-width: 1259px) {
    width: auto;

    margin: 2.5rem 4.5rem 1.5rem 1.5rem;
  }

  @media (max-width: 767px) {
    overflow-x: scroll;

    width: auto;

    margin: 2.5rem 0 0;
    padding: 0px 24px;

    scrollbar-width: none; /* mozilla */

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const HeroCardsContainer = styled.div<IProgressCircleProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  & > div > div {
    max-width: 18rem;
  }

  @media (max-width: 660px) {
    & > div {
      width: inherit;
      height: inherit;
    }
  }
`;
