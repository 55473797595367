import { enUS, es, ptBR } from "../../i18n";

type TypeInternationalization = "enUS" | "es" | "ptBR";

const showTexts = (internationalization: TypeInternationalization) => {
  const i18n = { enUS, es, ptBR };

  return {
    heroCards: i18n[internationalization].heroCards,
    heroContent: i18n[internationalization].heroContent,
  };
};

export default showTexts;
